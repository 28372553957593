// /* custom.scss */
// /* change theme colors */
// $primary: #362ec4;
// $secondary: #8f5325;
// $success: #3e8d63;
// $info: #7854e4;
// $warning: #b8c924;
// $danger: #d62518;

// $body-bg: #000;
// $body-color: #eee;
// /* end custom.scss */

// // Import Bootstrap and its default variables
// @import "~bootstrap/scss/bootstrap.scss";

// $primary: #007daf;
// $primary-alt: #6118c0; // dark mode

// @import "~bootstrap-dark-5/scss/bootstrap.scss";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/utilities";

@media (prefers-color-scheme: dark) {
    // .btn-primary {
    //     background-color: rgb(211, 0, 0);
    // }

    .bg-info-to-warning {
        background: rgb(23,162,184);
        background: linear-gradient(90deg, rgba(23,162,184,1) 0%, rgba(243,156,18,1) 100%)!important;
    }

    .bg-warning-to-danger {
        background: rgb(243,156,18);
        background: linear-gradient(90deg, rgba(243,156,18,1) 0%, rgba(231,76,60,1) 100%);
    }

    .bg-danger-to-success {
        background: rgb(231,76,60);
        background: linear-gradient(90deg, rgba(231,76,60,1) 0%, rgba(0,188,140,1) 100%);
    }

    .bg-danger-to-warning {
        background: rgb(231,76,60);
        background: linear-gradient(90deg, rgba(231,76,60,1) 0%, rgba(243,156,18,1) 100%);
    }
 
    .bg-warning-to-success {
        background: rgb(243,156,18);
        background: linear-gradient(90deg, rgba(243,156,18,1) 0%, rgba(0,188,140,1) 100%);
    }

    .bg-success-to-danger {
        background: rgb(0,188,140);
        background: linear-gradient(90deg, rgba(0,188,140,1) 0%, rgba(231,76,60,1) 100%);
    }
    
}

@media (prefers-color-scheme: light) {
    // .btn-primary {
    //     background-color: rgb(211, 0, 0);
    // }

    .bg-info-to-warning {
        background: rgb(13, 202, 240);
        background: linear-gradient(90deg, rgba(13, 202, 240,0.7) 0%, rgba(255, 193, 7,0.7) 100%)!important;
    }

    .bg-warning-to-danger {
        background: rgb(255, 193, 7);
        background: linear-gradient(90deg, rgba(255, 193, 7,0.7) 0%, rgba(220, 53, 69,0.7) 100%);
    }

    .bg-danger-to-success {
        background: rgb(220, 53, 69);
        background: linear-gradient(90deg, rgba(220, 53, 69,0.7) 0%, rgba(25, 135, 84,0.7) 100%);
    }

    .bg-danger-to-warning {
        background: rgb(220, 53, 69);
        background: linear-gradient(90deg, rgba(220, 53, 69,0.7) 0%, rgba(255, 193, 7,0.7) 100%);
    }
 
    .bg-warning-to-success {
        background: rgb(255, 193, 7);
        background: linear-gradient(90deg, rgba(255, 193, 7,0.7) 0%, rgba(25, 135, 84,0.7) 100%);
    }

    .bg-success-to-danger {
        background: rgb(25, 135, 84);
        background: linear-gradient(90deg, rgba(25, 135, 84,0.7) 0%, rgba(220, 53, 69,0.7) 100%);
    }

    .dramQProbTemplateTable .bg-danger {
        background: rgba(220, 53, 69, 0.7)!important;
    }

    .dramQProbTemplateTable .bg-warning {
        background: rgba(255, 193, 7, 0.7)!important;
    }

    .dramQProbTemplateTable .bg-success {
        background: rgba(25, 135, 84, 0.7)!important;
    }
}


option[value=""][disabled] {
    display: none;
}


.list-to-comma-str figcaption {
    display: inline;
    font-weight: bold;
}

.list-to-comma-str figcaption:after {
    content: ": ";
}

.list-to-comma-str ul {
    display: inline;
    list-style: none;
    padding-left: 0;
}

.list-to-comma-str li {
    display: inline;
}

.list-to-comma-str li + li:before {
    content: ", ";
}

.gen-controls {
    display: inline-block;
    width: 100%;
}

.lock {
    float: right;
}

.char-count {
    float: left;
}

.text-loading {
    opacity: 0.7;
}

.btn-block {
    display: block;
    width: auto;
}

.btn-no-border {
    border: none;
}

.carousel-inner {
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 2em;
}

.carousel-control-prev,
.carousel-control-next {
    top: 0.5em;
    bottom: auto;
    width: auto;
}

.vertical-text {
    writing-mode: vertical-lr;
    text-orientation: mixed;
}

.personality-w-20,
.personality-w-40,
.personality-w-60 {
    text-align: center;
    vertical-align: middle;
}

.personality-w-20 {
    width: 20%;
}

.personality-w-40 {
    width: 40%;
}

.personality-w-60 {
    width: 60%;
}

.button-grid,
.button-grid > div,
.button-grid > div > div {
    margin: 0;
    padding: 0;
}

.button-grid .btn-outline-secondary {
    border: none;
}

.btn-circle.btn-lg {
    width: 2em;
    height: 2em;
    padding: 0;
    border-radius: 1em;
    font-size: 1.5em;
    text-align: center;
}

.btn-circle.btn-lg * {
    vertical-align: -7%;
}

.genres-multi-select, .appeal-terms-multi-select {
    z-index: 9999;
}